import React from 'react';
import moment, { Moment } from 'moment';
import { InputBase, MenuItem, withStyles, Select } from '@material-ui/core';

const StyledSelect = withStyles(theme => ({
  root: {
    outline: 'none!important',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: '15px / 50%',
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    fontSize: 16,
    fontWeight: 600,
    padding: '5px 15px',
    outline: 'none!important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

interface Props {
  value: Moment;
  start: Moment;
  end: Moment;
  onChange?: (newValue: Moment) => void;
}

const HistoryDateSelect: React.FC<Props> = (props) => {
  const [options, setOptions] = React.useState<Moment[]>([]);

  const handleChange = (e: React.ChangeEvent<{name?: string, value: unknown}>) => {
    if (props.onChange && typeof e.target.value === 'string') {
      props.onChange(moment(e.target.value));
    }
  }

  React.useEffect(() => {
    const _options: Moment[] = [];
    const start = moment(props.start.format('YYYY-MM'));
    const end = moment(props.end.format('YYYY-MM'));

    while (start.unix() <= end.unix()) {
      _options.unshift(start.clone());
      start.add('1', 'months');
    }

    setOptions(_options);
  }, [props.start.unix(), props.end.unix()]);

  return (
    <>
      <Select
        variant='outlined'
        native
        value={ props.value.format('YYYY-MM') }
        input={ <StyledSelect /> }
        onChange={ handleChange }
      >
        {options.map(optionData => (
          <option key={ optionData.unix() } value={ optionData.format('YYYY-MM') }>
            { optionData.format('YYYY年MM月') }
          </option>
        ))}
      </Select>
    </>
  );
}

export default HistoryDateSelect;