import React from 'react';
import CircleIcon from '../../../components/icon/CircleIcon';
import TriangleIcon from '../../../components/icon/TriangleIcon';
import CrossIcon from '../../../components/icon/CrossIcon';

export function congestionPipe(value: number): React.ReactNode {
  if (value < 6) {
    return <CircleIcon />;
  } else if (value < 11) {
    return <TriangleIcon />;
  } else {
    return <CrossIcon />;
  }
}