import React from 'react';

interface Props {}

const TriangleIcon: React.FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" style={{ verticalAlign: 'bottom' }} viewBox="0 0 32 32"><g transform="translate(171 -172)"><g transform="translate(-171 175)" fill="none"><path d="M16,0,32,26.839H0Z" stroke="none"/><path d="M 16 3.905746459960938 L 3.520736694335938 24.83870506286621 L 28.47926330566406 24.83870506286621 L 16 3.905746459960938 M 16 -5.7220458984375e-06 L 32 26.83870506286621 L 0 26.83870506286621 L 16 -5.7220458984375e-06 Z" stroke="none" fill="#fff"/></g><rect width="32" height="32" transform="translate(-171 172)" fill="none"/></g></svg>
  );
}

export default TriangleIcon;