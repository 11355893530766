import React from 'react';

interface Props {}

const CrossIcon: React.FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" style={{ verticalAlign: 'bottom' }} viewBox="0 0 32 32"><g transform="translate(170 -126)"><g transform="translate(10.5 26.5)"><line x2="26" y2="26" transform="translate(-177.5 102.5)" fill="none" stroke="#fff" stroke-width="2"/><line x1="26" y2="26" transform="translate(-177.5 102.5)" fill="none" stroke="#fff" stroke-width="2"/></g><rect width="32" height="32" transform="translate(-170 126)" fill="none"/></g></svg>
  );
}

export default CrossIcon;