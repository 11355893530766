import React from 'react';

interface Props {}

const CircleIcon: React.FC<Props> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" style={{ verticalAlign: 'bottom' }} viewBox="0 0 32 32"><g fill="none" stroke="#fff" stroke-width="2"><circle cx="16" cy="16" r="16" stroke="none"/><circle cx="16" cy="16" r="15" fill="none"/></g></svg>
  );
}

export default CircleIcon;