import React from 'react';
import PageTitle from '../../../common/PageTitle/PageTitle';
import HistoryTable from '../HistoryTable/HistoryTable';
import { FetchStatus } from '../../../../utils/type/FetchStatus';
import { UsageHistory as IUsageHistory } from '../../../../utils/interface/UsageHistory';
import moment, { Moment } from 'moment';
import { ReduxState } from '../../../../redux/reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as usageHistoryActions from '../../../../redux/actions/UsageHistoryAction';
import { PrivateRouteComponentProps } from '../../../../containers/common/PrivateRoute/PrivateRoute';
import { parse } from 'query-string';
import { makeStyles, Typography } from '@material-ui/core';
import Progress from '../../../common/Progress/Progress';
import HistoryDateSelect from '../HistoryDateSelect/HistoryDateSelect';
import EmptyMessage from '../../../common/EmptyMessage/EmptyMessage';
import { UsageHistoryData } from '../../../../utils/interface/UsageHistoryData';
import { usageHistoryStateKey } from '../../../../redux/reducers/usageHistory';
import usageHistoryDataSelector from '../../../../redux/selectors/usageHistory/usageHistoryDataSelector';

const useStyles = makeStyles({
  content: {
    padding: '30px 16px'
  },
  date: {
    marginBottom: 30
  },
  total: {
    display: 'flex',
    minWidth: 0,
    alignItems: 'flex-end',
    marginBottom: 25,
    paddingBottom: 5,
    borderBottom: '1px solid #707070'
  },
  totalText: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.35,
  },
  totalTime: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1,
    marginLeft: 'auto',
  },
  totalTimeNumber: {
    fontSize: 40,
    lineHeight: 1,
  },
  menuItem: {
    fontWeight: 600
  }
});

interface Props extends PrivateRouteComponentProps {
  fetchStatus: FetchStatus;
  usageHistoryData: UsageHistoryData;
  lastFetchTimestamp: Moment | null;
  loadHistoryData: (date: Moment) => void;
  cancelLoading: () => void;
}

const UsageHistory: React.FC<Props> = (props) => {
  const classes = useStyles();

  const queryParams = parse(props.location.search);
  const dateParam: Moment = typeof queryParams.date === 'string'
    ? moment(queryParams.date)
    : moment();
  const [date, setDate] = React.useState<Moment>(dateParam.isValid() ? dateParam : moment());

  const handleMonthChange = (newValue: Moment) => {
    props.loadHistoryData(newValue);

    setDate(newValue);

    props.history.replace({
      pathname: '/history',
      search: `?date=${ newValue.format('YYYY-MM') }`
    });
  }

  const totalMinutes = (total: number): JSX.Element => {
    const hour = Math.floor(total / 60);
    const minutes = total - hour * 60;

    return (
      <>
        <span className={ classes.totalTimeNumber }>{ hour }</span>時間<span className={ classes.totalTimeNumber }>{ `${minutes < 10 ? '0' : ''}${minutes}` }</span>分
      </>
    );
  }

  React.useEffect(() => {
    if (props.fetchStatus !== 'loaded') {
      props.loadHistoryData(date);
    }

    return () => {
      props.cancelLoading();
    }
  }, []);

  return (
    <>
      <PageTitle>利用履歴</PageTitle>

      <div className={ classes.content }>
        <div className={ classes.date }>
          <HistoryDateSelect
            value={ date }
            start={ moment(props.authUser.definitive_registered_at) }
            end={ moment() }
            onChange={ handleMonthChange }
          />
        </div>
        <div className={ classes.total }>
          <Typography className={ classes.totalText }>今月の合計</Typography>
          <Typography className={ classes.totalTime }>
            { totalMinutes(props.usageHistoryData.total_minutes) }
          </Typography>
        </div>

        {props.fetchStatus === 'loading' && (<Progress />)}

        {props.fetchStatus === 'loaded' && (
          props.usageHistoryData.histories.length > 0 ? (
            <HistoryTable histories={ props.usageHistoryData.histories } />
          )
          : (
            <EmptyMessage>
              { `${moment(date).format('YYYY年MM月')}の利用履歴はありません` }
            </EmptyMessage>
          )
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state: ReduxState, ownProps: PrivateRouteComponentProps) => {
  const queryParams = parse(ownProps.location.search);
  const dateParam: Moment = moment(typeof queryParams.date === 'string' ? queryParams.date : undefined);
  const date = dateParam.isValid() ? dateParam : moment();
  const key = usageHistoryStateKey(date);

  return {
    fetchStatus: state.usageHistory[key] ? state.usageHistory[key].fetchStatus : null,
    usageHistoryData: usageHistoryDataSelector(state, key),
    lastFetchTimestamp: state.usageHistory[key] ? state.usageHistory[key].lastFetchTimestamp : null,
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadHistoryData: (date: Moment) => dispatch(usageHistoryActions.fetchUsageHistoryData.started(date)),
  cancelLoading: () => dispatch(usageHistoryActions.cancelFetchingUsageHistoryData())
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageHistory);